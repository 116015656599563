var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 px-5"},[_c('validation-observer',{ref:"observerProductForm"},[_c('form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"reference","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"br",attrs:{"disabled":_vm.readOnly,"outlined":"","counter":50,"maxlength":50,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('non_compliant_product.reference'),"required":""},model:{value:(_vm.ncpModel.alertKey),callback:function ($$v) {_vm.$set(_vm.ncpModel, "alertKey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"ncpModel.alertKey"}})]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"file","rules":"required|size:3000|ext:pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"br",attrs:{"outlined":"","accept":"application/pdf","show-size":"","error-messages":errors[0] == 'El campo archivo debe ser menor a 3000KB' ?
                'El campo archivo debe ser menor o igual a 3MB' :
                errors,"required":"","label":_vm.$t('non_compliant_product.upload_file')},model:{value:(_vm.ncpModel.path),callback:function ($$v) {_vm.$set(_vm.ncpModel, "path", $$v)},expression:"ncpModel.path"}})]}}])})],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"observation","rules":"min:1|max:2000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-textarea',{staticClass:"br",attrs:{"disabled":_vm.readOnly,"outlined":"","error-messages":errors,"label":_vm.$t('label.comment_optional'),"counter":"","maxlength":2000,"rows":"4","append-icon":"feather icon-message-square","required":"","oninput":"this.value = this.value.toUpperCase()"},model:{value:(_vm.ncpModel.comment),callback:function ($$v) {_vm.$set(_vm.ncpModel, "comment", $$v)},expression:"ncpModel.comment"}})]}}])})],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }