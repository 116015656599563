<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900"
      hide-overlay
    >
      <v-card class="px-4 pt-5">
        <v-toolbar class="header mx-0">
          <v-toolbar-title >
            <div class="row">
              <div class="col-12 text-body-2 text-md-h6 ">
                <span class="pl-md-5 pr-md-10">
                  {{ $t("shipments.shipments_title_transit") }}: 
                  {{ stockId }}
                </span> 
                <br class="d-flex d-md-none" >
                <span class="pl-md-5">
                  {{ $t("non_compliant_product.branch_origin") }}: 
                  {{ dataList[0] ? dataList[0].branchOfficeOrigen : null }}
                </span> 
              </div>
            </div>

          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <AlertMessageComponent ref="alertModal" />
            <div class="col-md-12 px-0 pb-6">
              <v-data-table
                :headers="onTransitProduct"
                :items="dataList"
                height="auto"
                :search="search"
                :footer-props="{
                  'items-per-page-text': $t('table.item_table'),
                  'items-per-page-all-text': $t('table.all'),
                  'items-per-page-options': [10, 25, 50, -1],
                }"
                :loading-text="$t('table.loading')"
                :no-data-text="$t('table.no_results_available')"
                :no-results-text="$t('table.no_results_search')"
                dense
              >
                <template v-slot:top>
                  <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <v-text-field
                        v-model="search"
                        :label="$t('table.search')"
                      ></v-text-field>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.statusShipmentText`]="{ item }">
                  <ChipTableComponent
                    :status="item.statusShipment"
                    :statusName="item.statusShipmentText"
                  />
                </template>
              </v-data-table>
            </div>
          
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @component ModalOnTransitComponent
 * @desc  Muestra los detalles de envios en transito de un producto.
 * @author Yair Alvarez
 */
import { ON_TRANSIT } from "@/core/DataTableHeadersLocale";
import ChipTableComponent from "@/common/table/ChipTableComponent";

export default {
  props: {
/** @vprop {} .*/
  },
  data() {
    return {
      /** * @member {Object} onTransitProduct - Default headers for transit table. / Cabeceras default para la tabla de transito. */
      /** * @member {Boolean} dialog          - Dialog default value. / Valor default del Dialog. */
      /** * @member {string} search           - Search input. / Input de busqueda. */
      /** * @member {Array|Object} dataList   - Table data. / Datos de tabla. */
      /** * @member {string} stockId          - Stock id. / Id de stock. */

      onTransitProduct: ON_TRANSIT(),
      dialog: false,
      search: "",
      dataList: [],
      stockId:''
    };
  },

    /**
    * @description Table Component / Componente utilizado en la tabla.
    * @prop {Components} ChipTableComponent {@link component:ChipTableComponent} - Shows chip component. / Permite visualizar el componente de chip.
    */
  components: {
    ChipTableComponent
  },
  methods: {
    /**
    * Reloads modal view. / Recarga la vista del modal.
    * @method 
    * @param {Object} data    - Product data. / Datos del producto. 
    * @param {string} stockId - Selected item Id Stock. / Id stock del articulo seleccionado.
    * @param {boolean} dialog - Open/Close modal. / Abrir/Cerrar modal.
    */
    reloadComponent (data,stockId) {
      this.dataList = [];
      this.dataList = data;
      this.stockId = stockId;
      this.dialog = true;
    },
  },
};
</script>
