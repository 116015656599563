<template>
  <div class="row">
    <div class="col-lg-12 pt-0">
          <v-data-table
            :headers="headers"
            :items="modelProduct"
            height="auto"
            fixed-header
            :footer-props="{
              'items-per-page-options': [15],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat class="btn-right">
                <v-spacer></v-spacer>
                <v-dialog
                  v-model="dialog"
                  max-width="1000"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      class="btn btn-outline-success float-right "
                      v-bind="attrs"
                      v-on="on"
                      >
                        <i class="ti-plus mr-2"></i>
                        {{ $t("global.add_product") }}   
                    </button>
                  </template>
                 
                    <v-card class="px-4 pt-5">
                      <validation-observer ref="observerNCP" v-slot="{ invalid }">
                        <form @submit.prevent="validSearch">
                          <v-toolbar class="header">
                            <v-toolbar-title>
                              <i class="feather icon-plus mr-4"></i>
                              <span class="text-h6">{{ formTitle }}</span>
                            </v-toolbar-title>
                          </v-toolbar>
            
                          <v-card-text>
                            <div class="row pt-8 pb-0" style="display: flex;">

                              <div class="col-12">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="product"
                                  rules="required"
                                >
                                  <v-autocomplete
                                    class="br"
                                    outlined
                                    :error-messages="errors"
                                    :items="productsList"
                                    v-model="editedItem.product"
                                    item-text="text"
                                    item-value="idProduct"
                                    :label="$t('label.product')"
                                    return-object
                                    @change="getLots"
                                    :disabled="editedIndex!=-1"
                                  >
                                    <template slot="item" slot-scope="data">
                                      <div class="pt-3 pb-3">
                                        {{ data.item.text }} 
                                      </div>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                      <div class="pt-5">
                                        {{ data.item.text }} 
                                      </div>
                                    </template>  
                                  </v-autocomplete>
                                </validation-provider>
                              </div>

                              <div class="col-md-6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="lot"
                                  rules="required"
                                >
                                  <v-autocomplete
                                    v-model="editedItem.lot"
                                    :items="lots"
                                    item-text="lot"
                                    item-value="lot"
                                    label="Lotes*"
                                    multiple
                                    clearable
                                    :error-messages="errors"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <v-chip color="secondary"
                                      >
                                        <span>{{ item.lot }}</span>
                                      </v-chip>
                                      <!-- <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                      >
                                        (+{{ editedItem.lot.length - 1 }})
                                      </span> -->
                                    </template>
                                  </v-autocomplete>
                                </validation-provider>
                              </div>

                              <div class="col-md-6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="supplier"
                                >
                                  <v-autocomplete
                                    :items="suppliers"
                                    item-text="text"
                                    item-value="value"
                                    v-model="editedItem.supplier"
                                    clearable
                                    :error-messages="errors"
                                    :no-data-text="$t('label.no_results')"
                                    :label="$t('label.supplier_optional_')"
                                    return-object
                                  ></v-autocomplete>
                                </validation-provider>
                              </div>
                            </div>                     
                          </v-card-text>
              
                          <v-card-actions class="pb-4 pr-4">
                            <v-spacer></v-spacer>
                            <button
                              class="btn btn-outline-success mr-2"
                              type="button"
                              @click="save"
                              :disabled="invalid"
                            >
                            <i class="feather icon-file-plus mr-1"> </i>
                            Agregar
                            </button>
                            <button
                              class="btn btn-outline-danger"
                              type="button"
                              @click="close"
                            >
                              <i class="feather icon-alert-circle mr-1"> </i> 
                              Cancelar
                            </button>
                          </v-card-actions>
                        </form>
                      </validation-observer>
                    </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template  v-slot:[`item.lot`]="{ item }">
              <v-tooltip v-if="item.lot != null && item.lot.length > 0" top>
                <template v-slot:activator="{ on, attrs }">
                  <div 
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-chip color="secondary" dark>
                      {{ item.lot[0] }}
                    </v-chip>
                    {{ item.lot.length != 1 ? "(+" + (item.lot.length - 1) + ")" :"" }}  
                  </div>
                </template>
                <span v-for="(lot) in item.lot.slice(1)"
                :key="lot"
                >  {{ lot }} <br>
                </span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi mdi-square-edit-outline
                  </v-icon>
                </template>
                <span>{{ $t("table.edit") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                  feather icon-trash-2
                  </v-icon>
                </template>
                <span>{{ $t("table.delete") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
    
          <div v-if="modelProduct.length != 0" class="row pt-5 pb-0 pr-0">
            <div class="col pb-0">
              <div class="float-right">
                <button
                  @click="validSearch"
                  class="btn btn-outline-success"
                >
                  <i class="feather icon-search mr-1"></i>
                  {{ $t("global.search") }} 
                </button>
              </div>
            </div>
          </div> 

    </div>
  </div>
</template>

<script>
/**
* @component NonCompliantProductAddProductFormComponent
* @desc Permite agregar productos a una lista de busqueda para la creacion de un movimiento.
* @author Yair Alvarez
*/

/* Vuex */
import { mapMutations, mapState } from "vuex";
import { NON_COMPLIANT_PRODUCT_SEARCH_FORM} from "@/core/DataTableHeadersLocale";
import { NonCompliantProductRequest } from "@/core/request";

export default {
  props: {
    /** @vprop {Array|Object} [modelProduct = []] - Saves product data. / Guarda datos del producto.*/
    /** @vprop {Function} [alert]                 - Show alert function. / Funcion de mostrar alerta.*/

    modelProduct: {
      type:Array,
      default(){
        return []
      }
    },
    alert:Function,  
    resetSearch:Function,  
  },
  data() {
    return {
    /** * @member {Array|Object} productsList - Product list to search. / Lista de productos a buscar. */
    /** * @member {Array|Object} lots         - Saves product lots. / Guarda los lotes de producto. */
    /** * @member {Object} headers            - Default headers for the table. / Cabeceras default para la tabla. */
    /** * @member {Boolean} dialog            - Default value for dialog. / Valor default para el dialog. */
    /** * @member {Object} editedItem         - Model for add/edit product. / Modelo para Agregar/Editar producto. */
    /** * @member {Object} defaultItem        - Default model product. / Modelo default de producto. */
    /** * @member {number} editedIndex        - Default value for index. / Valor default para index. */

      productsList:[],
      lots: [],
      headers: NON_COMPLIANT_PRODUCT_SEARCH_FORM(),
      dialog: false,
      editedItem: {
        product: "",
        lot: null,
        supplier: null
      },
      defaultItem: {
        product: "",
        lot: null,
        supplier: null
      },
      editedIndex: -1,
    };
  },
  computed: {

    /**
    * @computed {mapState} 
    */
    ...mapState("locales", ["lang"]),

    /**
    * @computed {mapState} remission{listSupplier} - Sets suppliers list. / Establece lista de proveedores.
    */
     ...mapState("remission", {
         suppliers: "listSupplier",
     }), 

    /**
    * @computed {String} title - Sets and returns add/edit modal title. / Establece y retorna titulo del modal Agregar/Editar.
    */
    formTitle () {
      return this.editedIndex === -1 ? 'Agregar producto' : 'Editar producto'
    },
  },

  watch: {
    /** 
    * @watch dialog
    * @desc Call close function if val = false
    */
    // dialog (val) {
    //   val || this.close() 
    // },  
  },

  methods: {
    ...mapMutations({
       listAllSuppliers: "remission/SET_SUPPLIER",
    }),
    /**
    * Gets lots on product change.
    * Obtiene lotes al cambiar el producto.
    * @method
    * @param {Array|Object} lots - Product lots array. 
    */
    getLots () { 
      this.lots = [];
      if(this.editedIndex == -1) this.editedItem.lot = null;
      this.getLotsNonCompliantProducts(this.editedItem.product.idProduct)
    },

    /**
    * Adds selected item data to editedItem model. / Agrega datos del elemento seleccionado al modelo editedItem.
    * Calls getLots function. / Llama la funcion getLots.
    * Opens Add/Edit modal. / Abre modal Agregar/Editar.
    * @method
    * @param {Array|Object} lots - Product lots. 
    */
    editItem (item) {
      this.editedIndex = this.modelProduct.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.getLots();
      this.dialog = true
    },

    /**
    * Shows delete alert. / Muestra alerta de borrado. --
    * Adds selected item data to editedItem model. /  Agrega datos del elemento seleccionado al modelo editedItem. --
    * Calls deleteItemConfirm() function. / LLama funcion deleteItemConfirm.
    * @method
    * @param {Object} editedItem -Selected product model. 
    */
    async deleteItem (item) {
      this.editedIndex = this.modelProduct.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const { isConfirmed } = await this.$SwalConfirm.fire({
        icon: 'warning',
        html: "El siguiente producto <b>" +
        this.editedItem.product.productKey + "</b> </br>"+
        " será borrado de la lista de búsqueda",
      });
      if (!isConfirmed) {
        return;
      }
      this.deleteItemConfirm();
    },

    /**
    * Deletes item from the array. / Elimina el articulo del arreglo. --
    * Assigns total of products number. / Asigna el numero total de productos. --
    * Call closeDelete() function. / Llama la funcion closeDelete().
    * @method
    * @param {Object} editedItem -Selected product model. 
    */
    deleteItemConfirm () {
      this.modelProduct.splice(this.editedIndex, 1)
      this.closeDelete()
      this.modelProduct.map((element,index) =>{
        element.product.number = index + 1
      })
    },

    /**
    * Closes modal and resets data. / Cierra modal y reestablece datos. --
    * Adds default empty model to editedItem model. / Agrega el modelo vacio default al modelo editedItem.
    * @method
    * @param {Object} editedItem -Selected product model. 
    */
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.observerNCP.reset();  
        this.lots = []; 
      })
    },

    /**
    * Resets data. / Reestablece datos. --
    * Adds default empty model to editedItem model. / Agrega el modelo vacio default al modelo editedItem.
    * @method
    * @param {Object} editedItem -Selected product model. 
    */
    closeDelete () {
      this.$nextTick(() => {
         this.editedItem = Object.assign({}, this.defaultItem)
         this.editedIndex = -1
         this.resetSearch();
       })
     },

    /**
    * Adds products if product list < 15 and no duplicate product exist. / Agrega productos si la lista > 15 y si no existe productos duplicados. --
    * Adds edited products. / Agrega productos editados. --
    * Calls alert function. / Llama la funcion de alert. --
    * Calls close function. / Llama la funcion de close.
    * @method
    * @param {Object} editedItem - Product to validate.
    * @param {Object} modelProduct - Product list to search.
    * @param {string} modelProduct.idProduct -
    * @param {string} modelProduct.lot -
    * @param {string} modelProduct.supplier - 
    */
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.modelProduct[this.editedIndex], this.editedItem)
        this.resetSearch();
        this.close();
      }
      else {
        if(this.modelProduct.length <= 14 && !this.validateProduct()){
          this.editedItem.product.number = this.modelProduct.length + 1;
          this.modelProduct.push(this.editedItem);
          this.close();
        }
        else if (this.validateProduct()) {
          this.alert("succes","Error_Search_Duplicate_Product_0001");
        }
        else {
          this.alert("succes","Error_Search_Number_Product_0001");
          this.close();
        }
      }
    },

    /**
    * Validates duplicate products. / Valida productos duplicados.
    * @method
    * @returns {Boolean} - True if a duplicate product exist.
    */
    validateProduct () {
      return this.modelProduct.some(({product}) => product.idProduct === this.editedItem.product.idProduct)
    },

    /**
    * Calls alertValidSearch function, sends model. / Llama la funcion alertValidSearch, envia modelo.
    * @method
    * @param {Array|Object} modelProduct - Product list to search.
    */
    validSearch () {
      this.$emit("alertValidSearch", this.modelProduct );
      //this.alert("succes","Error_Search_Duplicate_Product_0001");
    },


    /**
    * Gets products. / Obtiene productos.
    * @method
    * @param {Array|Object} productsList - Product list.
    */
    async getProductListNonCompliantProducts () {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.getProductListNonCompliantProducts()
      .then((response) => {
        let { data } = response;
        this.productsList = data;
        this.productsList.map( element => {
          element.text = element.productKey +  " | " 
          + element.description +  " | Presentación: " 
          + element.unitMeasurementDescription
        })
      })
      .catch((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
      })
    },

    /**
    * Gets lots. / Obtiene lotes.
    * @method
    * @param {Array|Object} lots - Lots list.
    */
    async getLotsNonCompliantProducts (value) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.getLotsNonCompliantProducts({
        idProduct:value
      })
      .then((response) => {
        let { data } = response;
        this.lots = data;
      })
      .catch((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
      })
    },

  },

  /**
  * Gets all products and suppliers. / Obtiene todos los productos y proveedores.
  * @method
  */
  async created () {
    this.getProductListNonCompliantProducts();
    this.listAllSuppliers();
  },
};
</script>
  <style scoped>
  /* .v-data-footer__icons-before {
    display: none!important;
  }
  .v-data-footer__icons-after {
    display: none!important;
  } */
  .v-toolbar {
    margin: 0px !important;
  }
  ::v-deep.btn-right .v-toolbar__content, .v-toolbar__extension {
    padding: 0 !important;
  } 

  ::v-deep.theme--light.v-btn.v-btn--disabled, .theme--light.v-btn.v-btn--disabled .v-btn__loading, .theme--light.v-btn.v-btn--disabled .v-icon {
    display: none!important;
  }
  </style>
