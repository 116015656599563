<template>
  <div class="row">
    <div class="col-12 px-5">
      <validation-observer ref="observerProductForm">
        <form>
          <div class="row">
            <div class="col-md-8">
              <validation-provider
                v-slot="{ errors }"
                name="reference"
                rules="required|max:50"
              >
                <v-text-field
                  class="br"
                  :disabled="readOnly"
                  outlined
                  v-model.trim="ncpModel.alertKey"
                  :counter="50"
                  :maxlength="50"
                  :error-messages="errors"
                  oninput="this.value = this.value.toUpperCase()"
                  :label="$t('non_compliant_product.reference')"
                  required
                ></v-text-field>
              </validation-provider>
            </div>

            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="file"
                rules="required|size:3000|ext:pdf"
              >
                <v-file-input
                  class="br"
                  outlined
                  v-model="ncpModel.path"
                  accept="application/pdf"
                  show-size
                  :error-messages="errors[0] == 'El campo archivo debe ser menor a 3000KB' ?
                  'El campo archivo debe ser menor o igual a 3MB' :
                  errors"
                  required
                  :label="$t('non_compliant_product.upload_file')"
                ></v-file-input>
              </validation-provider>
            </div>

            <div class="col-md-12">
              <validation-provider
                v-slot="{ errors }"
                name="observation"
                rules="min:1|max:2000"
              >
                <v-textarea
                  class="br"
                  :disabled="readOnly"
                  outlined
                  v-model="ncpModel.comment"
                  :error-messages="errors"
                  :label="$t('label.comment_optional')"
                  counter
                  :maxlength="2000"
                  rows="4"
                  append-icon="feather icon-message-square"
                  required
                  oninput="this.value = this.value.toUpperCase()"
                />
              </validation-provider>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
/**
 * @component NonCompliantProductFormComponent
 * @desc Permite agregar la informacion complementaria necesaria para la creacion de un movimiento. 
 * @author Yair Alvarez
 */
export default {
  props: {
    /** @vprop {Object} [ncpModel = {}]      - Create movement model. / Modelo creacion de movimiento. */
    /** @vprop {Boolean} [readOnly = 'false']  - Read only value. / Valor se solo lectura.*/
    ncpModel: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    /**
    * Validate inputs. / Validacion de inputs.
    * @method 
    * @returns {Boolean} isValid
    */
    async validateForm() {
      let isValid = await this.$refs.observerProductForm.validate();
      return isValid;
    },
  },
};
</script>
