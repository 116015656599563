var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2",staticStyle:{"z-index":"0","position":"relative"}},[(_vm.step == 100)?_c('div',{staticClass:"row pt-2 pb-8",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col-12 pb-0"},[_c('v-autocomplete',{attrs:{"outlined":"","multiple":"","clearable":"","items":_vm.filters.productKey,"label":_vm.$t('label.products')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.activeFilters.productKey.length - 1)+") ")]):_vm._e()]}}],null,false,1718975673),model:{value:(_vm.activeFilters.productKey),callback:function ($$v) {_vm.$set(_vm.activeFilters, "productKey", $$v)},expression:"activeFilters.productKey"}})],1),_c('div',{staticClass:"col-md-3 pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.filters.lot,"label":"Lotes","outlined":"","multiple":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.activeFilters.lot.length - 1)+") ")]):_vm._e()]}}],null,false,769136210),model:{value:(_vm.activeFilters.lot),callback:function ($$v) {_vm.$set(_vm.activeFilters, "lot", $$v)},expression:"activeFilters.lot"}})],1),_c('div',{staticClass:"col-md-6 pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.filters.nameSupplier,"outlined":"","clearable":"","multiple":"","no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.suppliers')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"color":"secondary","small":""}},[(item != null)?_c('span',[_vm._v(_vm._s(item))]):_vm._e()]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.activeFilters.nameSupplier.length - 1)+") ")]):_vm._e()]}}],null,false,2792336379),model:{value:(_vm.activeFilters.nameSupplier),callback:function ($$v) {_vm.$set(_vm.activeFilters, "nameSupplier", $$v)},expression:"activeFilters.nameSupplier"}})],1),_c('div',{staticClass:"col-md-3 pb-0"},[_c('validation-provider',{attrs:{"name":"lot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.caducidad,"label":"Caducidad","outlined":"","error-messages":errors},model:{value:(_vm.activeFilters.expired),callback:function ($$v) {_vm.$set(_vm.activeFilters, "expired", $$v)},expression:"activeFilters.expired"}})]}}],null,false,1296750103)})],1),_vm._m(0)]):_vm._e(),_c('v-data-table',{staticClass:"pt-0 pb-0",attrs:{"single-select":false,"show-select":_vm.showSelects,"headers":_vm.headers,"items":_vm.dataFiltered,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
     'items-per-page-options': [_vm.dataList.length],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","item-key":"stockId"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 mr-2 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.quantityToBlock",fn:function(props){return [_c('validation-observer',{ref:"observerNCP"},[_c('validation-provider',{attrs:{"name":"quantity","rules":("required|min_value:1|max_value:" + (( props.item.quantity)))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":!_vm.toBlock,"label":"Edit","single-line":"","error-messages":errors,"required":"","min":"0"},on:{"change":function($event){return _vm.inputText(props.item)}},model:{value:(props.item.quantity_to_block),callback:function ($$v) {_vm.$set(props.item, "quantity_to_block", $$v)},expression:"props.item.quantity_to_block"}})]}}],null,true)})],1)]}},{key:"item.disable",fn:function(ref){
    var item = ref.item;
return [(item.disable)?_c('v-chip',{staticClass:"m-1",attrs:{"color":"red","dark":""}},[_vm._v(" Inactivo ")]):_c('v-chip',{staticClass:"m-1",attrs:{"color":"green","dark":""}},[_vm._v(" Activo ")])]}},{key:"item.inTransit",fn:function(ref){
    var item = ref.item;
return [(item.inTransit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand text-fmedical-blue mr-2",on:{"click":function($event){return _vm.onTransit(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e()]}}],null,true),model:{value:(_vm.productAlertList[0]),callback:function ($$v) {_vm.$set(_vm.productAlertList, 0, $$v)},expression:"productAlertList[0]"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row py-0 pr-4"},[_c('div',{staticClass:"col pb-0"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-outline-success"},[_vm._v(" Aplicar filtro ")])])])])}]

export { render, staticRenderFns }