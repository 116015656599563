<template>
  <!-- Start Containerbar -->
  <div id="containerbar" data-app>
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("non_compliant_product.non_compliant_product") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("non_compliant_product.home") }} </a>
                </li>
                <li v-if="action == 1" class="breadcrumb-item">
                  <a href="#"> {{ $t(title) }} </a>
                </li>
                <li v-if="action > 1" class="breadcrumb-item">
                  <a href="#"> {{ $t("non_compliant_product.non_compliant_product") }} </a>
                </li>
                <li v-if="action == 2" class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li 
                v-if="action > 2" class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">

            <button
              class="btn btn-outline-success"
              v-if="action == 1 && withPermissionName(permissions.NCP_ADD_01)"
              @click="$router.push('/productonoconforme/crear')"
            >
              <i class="feather icon-plus mr-2 pu-5"></i>
              {{ $t("non_compliant_product.add_movement") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="action != 1"
              @click="$router.push('/productonoconforme/listar')"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 v-if="action == 1" class="card-title mb-0">
                      <i class="feather icon-file mr-2"></i>{{ $t(title) }}
                    </h5>
                    <h5 v-if="action == 2" class="card-title mb-0">
                      <i class="feather icon-file mr-2"></i>{{ $t(title) }}
                    </h5>
                    <h5 v-if="action > 2" class="card-title mb-0">
                      <i class="feather icon-file mr-2"></i>
                      {{ $t(title) }}: {{ ncpModel.idHealthAlert  }}
                    </h5>
                  </div>
                </div>
              </div>
              <NonCompliantProductTableComponent
                v-show="action == 1"
                ref="tableNonCompliantProduct"
                :listAllNonCompliantProducts="listAllNonCompliantProducts"
                :pagesAtributes="pagesAtributes"
                :headers="historyHeaders"
                @detail="detailsNonCompliantProducts"
                @alertReturnStock="alertReturnStock"
                :isDetail="withPermissionName(permissions.NCP_DETA_02)"
                :isReturn="withPermissionName(permissions.NCP_RET_03)"
              />
              <v-stepper v-if="action != 1"
                elevation="0"
                v-model="stepperModel"
                class="no-transition"
              >
                <v-stepper-header v-if="action == 2" >
                  <v-stepper-step
                    color="#0f9368"
                    :complete="stepperModel >= 1"
                    step=""
                  >
                    Búsqueda de productos
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    color="#0f9368"
                    :complete="stepperModel >= 2"
                    step=""
                  >
                    Confirmación de productos
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step
                    color="#0f9368"
                    :complete="stepperModel >= 3"
                    step="">
                     Información complementaria
                  </v-stepper-step>
                     <v-divider></v-divider>
                  <v-stepper-step
                    class="pr-16 pr-md-6"
                    color="#0f9368"
                    :complete="stepperModel >= 4"
                    step="">
                     Confirmación  
                  </v-stepper-step>
                </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pt-0 px-0 gey-color" step="1">
                      <v-card v-if="action == 2"
                        elevation="0"
                        class="mb-10 px-5"
                      >
                        <h6 class="pb-3">
                          <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
                          {{ $t("non_compliant_product.motive_selection") }}
                        </h6>
                        <validation-provider
                          v-slot="{ errors }"
                          name="movement_motive"    
                          rules="required|max:100"
                        >
                          <v-select
                            :items="arrayMotive"
                            v-model="ncpModel.motive"
                            outlined
                            required
                            :error-messages="errors"
                            oninput="this.value = this.value.toUpperCase()"
                            item-text="name"
                            return-object
                            :label="$t('non_compliant_product.movement_motive')"
                          ></v-select>
                        </validation-provider>
                        <div v-if="action == 4" class="col-12 pb-8">
                          <v-text-field
                            :value="ncpModel.motive.name"
                            outlined
                            disabled
                            :label="$t('non_compliant_product.movement_motive')"
                          ></v-text-field>
                        </div>
                      </v-card>

                      <v-card v-if="action != 4 && ncpModel.motive != null"
                        elevation="0"
                        class="mb-10 px-5 pt-6 pb-8"
                      >
                        <h6>
                          <v-icon class="pb-1" 
                           v-text="'mdi-numeric-2-box-outline'" 
                          />
                          Agregar productos a la lista de búsqueda
                        </h6>
                        <NonCompliantProductAddProductFormComponent
                        :modelProduct="modelProduct"
                        :alert="alert"
                        :resetSearch="resetSearch"
                        ref="NonCompliantProductAddProductFormComponent"
                        @alertValidSearch="alertValidSearch"
                        />
                       
                    </v-card>
                  
                        <div class="row px-4 pb-6"  v-if="action == 4">
                          <div class="col-12 pt-5 px-5 white rounded-lg">
                        <h6>
                          <v-icon class="pb-1" 
                           v-text="action < 3 
                            ? 'mdi-numeric-2-box-outline'
                            : 'mdi-numeric-1-box-outline'" />
                          {{ action != 4 
                            ? "Agregar productos a la lista de búsqueda"
                            : $t("non_compliant_product.data") }}
                        </h6>
                            <NonCompliantProductFormComponent
                              ref="productForm"
                              v-bind:ncpModel="ncpModel"
                              :loading="loading"
                            />  
                          </div>
                        </div>

                    <v-card  v-if="showTableResults"
                      elevation="0"
                      class="px-3 pt-6 pb-16"
                    >
                      <h6 class="pl-2">
                        <v-icon class="pb-1" 
                        v-text="'mdi-numeric-3-box-outline'" 
                        />
                        {{ $t("non_compliant_product.products_selection") }}
                      </h6>
                      
                      <NonCompliantProductSearchTableComponent
                        ref="productSearchTableNonCompliantProduct"
                        :headers="searchProductsHeader"
                        :showSelects="ncpModel.motive.value == 1 ? false : true"
                        :productAlertList="selected"
                        @onTransitNonCompliantProducts="onTransitNonCompliantProducts"
                      />

                      <div class="pt-8 pb-6">
                      <v-btn  
                        v-if="action == 2 && 
                        showTableResults"
                        class="float-right mr-1"
                        color="#93b4d4"
                        fab
                        outlined
                        @click="nextStep(2)"
                      >
                      <v-icon large>mdi mdi-arrow-right-bold</v-icon>
                      </v-btn>
                      </div>
                    </v-card>
               
          
                      <v-btn 
                        v-if="action == 4 && ncpModel.motive != null"
                        :disabled="this.ncpModel.alertKey == ''"
                        outlined
                        class="float-right mb-8 mr-5"
                        color="success"
                        @click="alertEditGeneralInfoMovement()"
                      >
                        Finalizar Edición
                      </v-btn>
                    </v-stepper-content>
                        
                    <v-stepper-content v-if="action == 2" 
                      class="px-3"
                      step="2">
                      <v-card
                        elevation="0"
                        class="mb-12"
                        v-if="showTableResults"
                      >
                    
                        <h6 class="pl-2">
                          <v-icon class="pb-1" v-text="'mdi-numeric-4-box-outline'" />
                          {{ $t("non_compliant_product.check_info") }}
                        </h6>
                  
                        <NonCompliantProductSearchTableComponent
                          class="pb-8"
                          ref="productSearchTableNonCompliantProduct2"
                          :headers="searchProductsHeader"
                          :showSelects="false"
                          :toBlock="false"
                          @onTransitNonCompliantProducts="onTransitNonCompliantProducts"
                        />
                      </v-card>

                      <v-btn
                        class="float-right mb-8 mr-1"
                        color="#93b4d4"
                        @click="stepperModel = 3"
                        fab
                        outlined
                      >
                      <v-icon large>mdi mdi-arrow-right-bold</v-icon>
                      </v-btn>

                      <v-btn 
                        @click="previousStep(1)" 
                        color="#93b4d4"
                        text
                        fab
                        outlined
                        class="mb-8 ml-1"
                      >
                      <v-icon large>mdi mdi-arrow-left-bold</v-icon>
                      </v-btn>
                    </v-stepper-content>

                    <v-stepper-content v-if="action == 2"
                      class="px-3"
                      step="3"
                    >
                      <v-card
                        elevation="0"
                        class="mb-12 px-1"
                        v-if="stepperModel == 3"
                      >
                      
                            <h6 class="pl-1">
                              <v-icon class="pb-1" v-text="'mdi-numeric-5-box-outline'" />
                              {{ $t("non_compliant_product.data") }}
                            </h6>
                     
                        <NonCompliantProductFormComponent
                        ref="nonCompliantProductFormComponent"
                          v-bind:ncpModel="ncpModel"
                          :loading="loading"
                        />  
                      </v-card>
                       
                      <v-btn   
                        class="mb-8 ml-1"
                        color="#93b4d4"
                        @click="stepperModel = 2"
                        text
                        fab
                        outlined
                      >
                      <v-icon large>mdi mdi-arrow-left-bold</v-icon>
                      </v-btn>

                      <v-btn
                        class="float-right mb-8 mr-1"
                        color="#93b4d4"
                        @click="validationForm()"
                        :disabled="ncpModel.path == null || 
                        ncpModel.path.size > 3000000"
                        fab
                        outlined
                      >
                      <v-icon large>mdi mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </v-stepper-content>
                        
                    <v-stepper-content v-if="action == 2" 
                      class="px-5 pt-0"
                      step="4"
                    >
                      <v-card
                        elevation="0"
                        class="mb-12"
                        v-if="showTableResults"
                      >
                        <div class="row mt-3">
                          <div class="col-12 py-0">
                            <v-card
                              class="mx-auto"
                              outlined
                              color="#f9f9f9"
                            >
                              <v-list-item >
                                <v-list-item-content class="px-5">
                                   <v-list-item-title>
                                    <div class="regular text-h6 text-md-h4">
                                      <i class="feather icon-file-text"> </i>
                                     {{ $t("non_compliant_product.terms_and_conditions") }}
                                    </div>
                                    <v-divider class="my-0" dark></v-divider>
                                    <v-divider class="mt-0" dark></v-divider>
                                    </v-list-item-title>
                                    <p
                                    class="regular pb-8 font-weight-medium "
                                    >
                                    Advertencia, la siguiente acción bloqueará todos las claves CNIS 
                                    seleccionadas y afectará a las sucursales de la siguiente manera:
                                    </p>

                                    <ul 
                                      class="regular px-10 pt-7 pb-2 text-uppercase rounded-lg border border-light white" 
                                     
                                    >
                                      <li class="pb-4">Todas las entradas que cuenten con claves CNIS, que hayan sido bloqueadas por el módulo de producto no conforme sin importar
                                        el motivo, no permitirá la modificación de información de los siguientes campos: lote, fecha de caducidad, cambio de clave y cantidades.</li>

                                      <li class="pb-4">Todas las claves CNIS etiquetadas dentro del módulo de producto no conforme, serán reubicadas a un nuevo almacén, llamado producto no conforrme.</li>


                                      <li class="pb-4">Todas las salidas que cuenten con claves CNIS en sus partidas y tengan un estatus diferente de EN TRANSITO Y RECEPCIONADO,
                                         retornaran automáticamente al almacén de producto no conforme.</li>

                                      <li class="pb-4">Todas las salidas que se encuentren dentro del estatus EN TRANSITO y cuenten con claves CNIS etiquetadas como no conforme, 
                                        no tendran la posibilidad de ser regresadas a su almacén origen.</li>

                                      <li class="pb-4">Las claves CNIS previamente etiquetadas, sin importar el motivo, no volverán a ser mostradas dentro de este módulo.</li>
                                    </ul>
                                      <v-container class="mx-0 px-0">
                                     <v-checkbox v-model="checkbox">
                                        <template v-slot:label >
                                            <div class="pt-2 regular font-weight-medium">
                                              Estoy de acuerdo.
                                            </div>
                                        </template>
                                      </v-checkbox>
                                      </v-container>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </div>
                        </div>  
                      </v-card>
                      <button
                        v-if="checkbox"
                        class="float-right mt-12 mb-8 mr-1 text-capitalize btn btn-outline-success"
                        color="success"
                        outlined
                        @click="alertSaveMovement()"
                      >
                      <i class="feather icon-lock mr-1"></i>
                        Finalizar
                      </button>
                      <v-btn  
                        class="mb-8 mt-10 ml-1" 
                        @click="stepperModel = 3"
                        color="#93b4d4"
                        text
                        fab
                        outlined
                      >
                      <v-icon large>mdi mdi-arrow-left-bold</v-icon>
                      </v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>

      <ModalMovementDetailComponent 
        :dialogM="dialogM"
        ref="modalMovementDetail"
        :detailsHeader="detailsHeader"
        :alert="alert"
        :onTransitNonCompliantProducts="onTransitNonCompliantProducts"
        :listAllNonCompliantProducts="listAllNonCompliantProducts"
        @downloadPDF="downloadPDFNonCompliantProducts"
      />
      <ModalOnTransitComponent
        ref="modalOnTransit" 
      />
  </div>
</template>

<script>
/**
* @module NonCompliantProductView
* @desc Producto No Conforme
* @author Yair Alvarez
*/
import ModalMovementDetailComponent from "@/components/non-compliant-product/NonCompliantProductModalDetailComponent";
import ModalOnTransitComponent from "@/components/non-compliant-product/NonCompliantProductModalOnTransitComponent";
import { NonCompliantProductRequest, PDFRequest } from "@/core/request";
import { 
  NON_COMPLIANT_PRODUCT_HISTORY, 
  NON_COMPLIANT_PRODUCT_SEARCH ,
  NON_COMPLIANT_PRODUCT_SEARCH_QUANTITY_TO_BLOCK,
  NON_COMPLIANT_PRODUCT_SEARCH_DETAILS,
} from "@/core/DataTableHeadersLocale";
import NonCompliantProductAddProductFormComponent from "@/components/non-compliant-product/NonCompliantProductAddProductFormComponent";
import NonCompliantProductFormComponent from "@/components/non-compliant-product/NonCompliantProductFormComponent";
import NonCompliantProductSearchTableComponent from "@/components/non-compliant-product/NonCompliantProductSearchTableComponent";
import NonCompliantProductTableComponent from "@/components/non-compliant-product/NonCompliantProductTableComponent";
import movementMotive from "@/assets/json/movementMotive.json";
import Swal from 'sweetalert2'

export default {
  props: {
    /** @vprop {} .*/
  },
  data() {
    return {
    /** * @member {Array} steps                   - Array of steps for SweetAlert progress loading . / Arreglo de steps del loading de progreso de SweetAlert. */
    /** * @member {Object} Queue                  - SweetAlert custom instance . / Instancia personalizada de Sweet Alert. */
    /** * @member {Boolean} checkbox              - Last step Checkbox default value . / Valor default de Checkbox del ultimo step. */
    /** * @member {Array|Object} productsList     - Product list to search. /  Lista de productos para buscar. */
    /** * @member {number} stepperModel           - Default value for step. / Valor defualt del step.*/
    /** * @member {Object} historyHeaders         - Default headers for movement history table. / Cabezeras default para la tabla de historial de movimientos.  */
    /** * @member {Object} searchProductsHeader   - Default headers for search products table. / Cabezeras default para la tabla de busqueda de productos. */
    /** * @member {Object} detailsHeader          - Default headers for details table. / Cabezeras default para la tabla de detalle. */
    /** * @member {number} action                 - Default value for view action (movement history). / Valor default para la accion de vista (historial de movimientos). */
    /** * @member {Object} permissions            - Permissions constants. / Constantes de permisos. */
    /** * @member {boolean} loading               - Default value for loading component. / Valor default para el componente de loading. */
    /** * @member {Array|Object} modelProduct     - Search product model. / Modelo de busqueda de producto. */
    /** * @member {boolean} showTableResults      - Default value for show product search table. / Valor default para mostrar la tabla de busqueda de productos. */
    /** * @member {Object} response               - Saves "search products" original data response. / Guarda la respuesta original de "busqueda de productos. "*/
    /** * @member {Array|Object} arrayMotive      - Motive movement values. / Valores de motivos de movimiento. */
    /** * @member {Object} ncpModel               - Movement model. / Modelo de movimiento. */
    /** * @member {Array|Object} selected         - Selected search products "prop". / Prop de busqueda de productos selecciones. */
    /** * @member {Object} dialogM                - Default value for show modal detail. / Valor default para mostrar el modal de detalle. */
    /** * @member {Array|Object} selectedProducts - Final selected products ordered by branch. / Productos finales seleccionados ordenados por sucursales. */
    /** * @member {Object} pagesAtributes         - Pagination default values. / Valores default del paginado. */
    
      steps:[],
      Queue:null,
      checkbox:false,
      productsList:[],
      stepperModel: 1,
      historyHeaders: NON_COMPLIANT_PRODUCT_HISTORY(),
      searchProductsHeader: NON_COMPLIANT_PRODUCT_SEARCH(),
      detailsHeader: NON_COMPLIANT_PRODUCT_SEARCH_DETAILS(),
      action: 1,
      permissions: this.$PermissionConstants,
      loading: false,
      modelProduct: [],
      showTableResults:false,
      response:{},
      arrayMotive: movementMotive,
      ncpModel:{
        motive:null,
        productAlertList:[],
        path:null 
      },
      selected:[],
      dialogM: {
        dialog: false,
      },
      selectedProducts:[], 
      pagesAtributes:{
        search: "",
        currentPage: 0,
        itemsPerPage: 15
      },
    };
  },
  computed: {
    /**
    * @computed {String} Sets and returns breadcrumbs and views titles. / Establece y retorna titulos de breadcrumbs y vistas.
    */
    title () {
      return this.action == 1
        ? "non_compliant_product.movement_history"
        : this.action == 2
        ? "non_compliant_product.new_movement"
        : "non_compliant_product.edit_movement"
    },
  },
  methods: {
    /**
    * Validates the type file (PDF) in the form. / Valida el tipo de archivo (PDF) en el formulario.
    * @method 
    */
    async validationForm () {
      let val = await this.$refs.nonCompliantProductFormComponent.validateForm();
      if(val) this.stepperModel = 4
      else this.alert("error","Error_File_0001");
    },

    /**
    * Validates module permissions. / Valida los permisos del modulo. 
    * @method 
    * @param {Object} permissions - Data permisions. 
    * @returns {boolean} - true if permission exist.
    */
    withPermissionName (permissions) {
      return this.$UtilFront.withPermissionName(permissions);
    },

    /**
    * Generates and downloads pdf file. / Genera y descarga archivo pdf.
    * @method 
    * @param {Object} item - idHealthAlert value . 
    */
    async downloadPDFNonCompliantProducts (item) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.downloadPDFNonCompliantProducts(
        {idHealthAlert: item}
      )
      .then((response) => {
        let { success , message , data } = response.data.data;
        if(success) {
          PDFRequest.downloadBase64PDF(data,message);
          this.$toast.success('Descargando documento PDF')
        }
        else {
          this.$toast.error(message);
        }
      })
      .catch ((error) => {
        console.log(error);
        this.$toast.error(error);
      })
      .finally(() =>{
        this.loading.hide();
      })
    },

    /**
    * Envia datos y muestra el componente de alerta. / Sends data and shows to alert component.
    * @method 
    * @param {string} type - Alert type / Tipo de alerta.
    * @param {string} message - Alert message. / Mensaje de alerta.
    */
    alert (type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },

    /**   
    * Sends original search data response to first step. / Envia datos de respuesta original al primer step.
    * @method 
    * @param {number} step - Step number.
    */
    previousStep (step) {
      this.$refs.productSearchTableNonCompliantProduct.reloadComponent(this.response,1,this.productsList);
      this.stepperModel = step;
    },

    /**
    * Creates array of selected products for second and third step. /
    * Crea un arreglo de productos seleccionados para el segundo y tercer step.
    * @method 
    * @param {number} step - Step number.
    * @param {Object[]} selectedProducts - Final selected products ordered by branch.
    * @param {string} selectedProducts[].branchOfficeOrigin - Branch's name.
    * @param {Object[]} selectedProducts[].stockList[] - Selected products.
    * @param {Object[]} selected - Selected products with no branch info.
    */
    nextStep (step) {
      let merged = this.selected.reduce(function(prev, next) {
        return prev.concat(next);
      });
      this.ncpModel.productAlertList = merged;
      if (this.ncpModel.productAlertList.length != 0) {
        this.stepperModel = step;
        this.$refs.productSearchTableNonCompliantProduct2.reloadComponent(this.selected[0],step);
      }
        // this.selectedProducts = [];
        // this.selected.map((element,index) =>{
        //   if(element.length != 0){
        //     this.selectedProducts.push({
        //     branchOfficeOrigin:this.response[index].branchOfficeOrigin,
        //     stockList: element
        //     })
        //   }
        //  })
        //}    
    },

    /**
    * Shows "to search products" confirmation alert. / Muestra alerta de confirmacion para "Busqueda de productos". --
    * Resets selected products array. / Reestablece arreglo de productos seleccionados. --
    * Enables data table component and calls search function. / Habilita el componente tabla y llama la funcion de busqueda.
    * @method 
    * @param {Object[]} selected - Selected products with no branch info.
    * @param {boolean} showTableResults - Sets true value to show table component.
    * @default [false]
    */
    async alertValidSearch (value) {
      if (this.ncpModel.motive == null ) this.alert("succes","Error_Motive_Movemement_0001");  
      else {
      const { isConfirmed } = await this.$SwalConfirm.fire({
        html: value.length != 1 ? 
          "Los siguientes <b>" +
          value.length + " productos </b></br> serán buscados." : 
          "El siguiente <b> producto </b></br> será buscado.",
      });
      if (!isConfirmed) {
        return;
      }
        this.selected = [];
        this.showTableResults = true;
        this.productsList = value;
        this.searchProductsNonCompliantProducts(this.createSearchModel());  
     }
    },

    /**
    * Searchs products and reload data table component. /
    * Busca productos y recarga los datos del componente tabla.
    * @method 
    * @param {Object[]} value - Products array.
    * @param {Object[]} response - Saves data response.
    * @param {Object[]} response[].stockList[].quantity_to_block - Sets "quantity to block" value .
    */
    async searchProductsNonCompliantProducts (productList) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.searchProductsNonCompliantProducts(
        productList
      ).then((response) => {
        let { data } = response;
        this.response = data;

        if (this.response.length > 0) {    
          this.response.map(item => {
            item.quantity_to_block = item.quantity;
          })
          // if(this.ncpModel.motive.value == 1) this.showSelects == false;
          this.$refs.productSearchTableNonCompliantProduct.reloadComponent(this.response,1);
        }
        else {
          this.showTableResults = false;
          this.alert("succes","Error_Search_Products_0001");
        } 
      })
      .catch ((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
      })
    },

    /**
    * Creates product list model to search. / 
    * Crea modelo de lista de productos a buscar.
    * @method 
    * @param {Object} searchModel - Search model
    * @returns {Object} searchModel
    */
    createSearchModel () {
      let searchModel = [];
      this.productsList.map(el => {
        searchModel.push({
          idProduct : el.product.idProduct,
          lots : el.lot,
          supplier : el.supplier ? el.supplier.value : null
        })    
      })
      return searchModel;
    },

    /**
    * Shows "save movement" confirmation alert. / Muestra alerta de confirmacion de "guardar movimiento". --
    * Calls save function and create final model. / Llama la funcion de guardar y crear modelo final.
    * @method 
    * @param {Object} ncpModel - Movement Model
    */
    async alertSaveMovement () {
      const { isConfirmed } = await this.$SwalConfirm.fire({
        html:
          "Los productos seleccionados quedaran bloqueados del almacén y de cualquier movimiento que se haya generado (Salidas)"
      });
      if (!isConfirmed) {
        return;
      }
      this.blockStockNewNonCompliantProducts (this.createFinalModel(this.ncpModel,"new"));
    },

    /**
    * Creates and return final model. / Crea y retorna modelo final.
    * @method 
    * @param {Object} model - Movement model to be modify.
    * @param {string} action - save or edit action.
    * @param {Object} finalModel - final movement model.
    * @param {Object[]} finalModel.productAlertList[] - Products to block with quantity and idStock.
    * @returns {Object} final model
    */
    createFinalModel (model,action) {
      let finalModel = {};
      finalModel.productAlertList =  [];
      model.productAlertList.map(element => {
        if (model.motive.value != 1) element.quantity = parseInt(element.quantity_to_block);
          finalModel.productAlertList.push({
            //quantity : parseInt(element.quantity_to_block),
            stockId : element.stockId
          }) 
      });
      if (action == "new" ) {
        finalModel.alertKey = model.alertKey;
        finalModel.comment = model.comment ? model.comment : ""
        finalModel.motive = model.motive.value;
        finalModel.path = model.path;
      }
      else {
        finalModel.idHealthAlert = model.idHealthAlert;
      }
      return finalModel
    },

    /**
    * Sets custom properties for create movement alert. /
    * Establece propiedades personalizadas para la alerta de crear movimiento.
    * @method 
    * @param {Array} steps - Steps array.
    * @param {Object} Queue - Instance alert.
    */
    setProgressSteps () {
      this.steps = ['1', '2','3','4']
      this.Queue = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-outline-success mr-2",
          cancelButton: "btn btn-outline-danger",
        },
        buttonsStyling: false,
        progressSteps: this.steps,
        showConfirmButton:false,
        showCancelButton:false,
        timerProgressBar: true,
        allowOutsideClick:false,
        // optional classes to avoid backdrop blinking between steps
        showClass: { backdrop: 'swal2-noanimation' },
        hideClass: { backdrop: 'swal2-noanimation' }
      })
    },

    /**
    * Sets and shows custom alert. / Establece y muestra alerta personalizada.
    * @method 
    * @param {string} value - Message value.
    * @param {number} step - Step number.
    * @param {Boolean} showButton -shows close button if true.
    * @param {string} icon - Icon Type.
    * @param {number} time - Value for timer.
    */
    async showSweetAlert (value, step, showButton, icon, time) {
      this.setProgressSteps();
      await this.Queue.fire({
        icon: icon,
        title: value,
        html: step < 3 ?
          'Procesando solicitud, eso puede tardar un momento' : null,
        currentProgressStep: step,
        showCancelButton: showButton,
        cancelButtonText: '<i class="feather icon-alert-circle mr-1 mr-1"></i> Cerrar',
        timer: time,
        // optional class to show fade-out backdrop animation which was disabled in Queue mixin
        showClass: { backdrop: 'swal2-noanimation' },
        didOpen: step !=3 && !showButton ? () => {  Swal.showLoading() } : null,
      })
    },

    /**
    * Saves new movement, shows response alert. / Guarda nuevo movimiento y muestra la alerta de respuesta.
    * @method 
    * @param {Object[]} model - General info and products to block model.
    */
    async blockStockNewNonCompliantProducts (model) {
      this.showSweetAlert('Bloqueando productos...',0,false,'success',null);
      let user = this.$UtilFront.getDataUser();
      this.loading = this.$UtilFront.getLoadding();
      let final = {
        idUser:user.idUser,
        productAlerts:model.productAlertList,
        alertKey: model.alertKey,
        comment :model.comment,
        jsonAlert: JSON.stringify(this.createSearchModel())
      }

      await NonCompliantProductRequest.blockStockNewNonCompliantProducts(
        final
      )
      .then((response) =>  {
        let { data } = response;
        if (data.idHealthAlert) {
          this.returnStockNewNonCompliantProducts(final,data.idHealthAlert,model)
        }
      })
      .catch ((error) => {
        this.showSweetAlert('Bloqueando productos...',0,true,'error',null);
        this.alert("error",error.message);
        this.loading.hide();
      })
      .finally(() => {});
    },

    /**
    * Saves new movement, shows response alert. / Guarda nuevo movimiento y muestra la alerta de respuesta.
    *@method 
    */
    async returnStockNewNonCompliantProducts (finalModel,idHealthAlert,model) {
      this.showSweetAlert('Regresando a stock...',1,false,'success',null);
      delete finalModel.alertKey;
      delete finalModel.comment;
      delete finalModel.jsonAlert;

      await NonCompliantProductRequest.returnStockNewNonCompliantProducts(
        finalModel
      ).then(() => { 
        this.updateFileNewNonCompliantProducts(idHealthAlert,model)
      })
      .catch ((error) => {
        console.error(error) 
        this.showSweetAlert('Regresando a stock...',1,true,'error',null);
        this.alert("internal");
        this.loading.hide();
      })
      .finally(() => { })    
    },

    /**
    * Saves new movement, shows response alert and returns to history view. /
    * Guarda nuevo movimiento , muestra la alerta de respuesta y retorna a la vista de historial.
    *@method 
    */
    async updateFileNewNonCompliantProducts (idHealthAlert,model) {
      const formData = new FormData();
      this.showSweetAlert('Subiendo archivo PDF',2,false,'success',null);
      let final = { idHealthAlert: idHealthAlert }
      formData.append("jsonBody",JSON.stringify(final))
      formData.append("alert",model.path?model.path:null)

      await NonCompliantProductRequest.updateFileNewNonCompliantProducts(
        formData
      ).then(() => {
        this.alert("success", "Add_Success_0001");
        this.showSweetAlert('Completado',3,false,'success',4000);
      })
      .catch ((error) => {
        console.error(error) 
        this.showSweetAlert('Subiendo archivo PDF',2,false,'error',null);
        this.showSweetAlert('Completado',3,false,'success',4000);  
        this.alert("internal");
        this.listAllNonCompliantProducts(this.pagesAtributes);
        this.$router.push('/productonoconforme/listar')
      })
      .finally(() => {
        this.loading.hide();
        this.listAllNonCompliantProducts(this.pagesAtributes);
        this.$router.push('/productonoconforme/listar')
      })
    },

    /** 
    * Shows confirmation alert for returnStockNonCompliantProducts function. /
    * Muestra la alerta de confirmacion para la funcion de devolver a almacen.
    *@method 
    */
    async alertReturnStock (item) {
      const { isConfirmed } = await this.$SwalConfirm.fire({
        icon: 'warning',
        html: "Si se devuelve este movimiento:<b> " +item.idHealthAlert + 
        " </b> , los productos que contiene se habilitarán y se devolverán " +
        "al almacén general de cada sucursal donde se encuentren,"+
        " quedando disponibles nuevamente para su distribución."
      });
      if (!isConfirmed) {
        return;
      }
      this.returnStockNonCompliantProducts(item.idHealthAlert)
    },

    /** Returns movement products to warehouse. / Devolver productos de movimiento a almacen.
    *@method 
    */ 
    async returnStockNonCompliantProducts (idHealthAlert) {
      this.loading = this.$UtilFront.getLoadding();
      let user = this.$UtilFront.getDataUser();

      await NonCompliantProductRequest.returnStockNonCompliantProducts({
        idUser:user.idUser,
        idHealthAlert:idHealthAlert,
      })
      .then((response) => {
        let { data } = response;
        this.alert("success", "La devolución se ha realizado correctamente");
      })
      .catch((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
        this.listAllNonCompliantProducts(this.pagesAtributes);
      })
    },

    /**
    * Gets details of selected item. / Obtiene detalles del elemento seleccionado. --
    * Sets movement info. / Establece la informacion del movimiento. --
    * Sends data response to detail component. / Envia datos de respuesta al componente de detalle
    * @method 
    * @param {Object} item - Selected item.
    * @param {number} view - View validation.
    * 1 = create view (enables action buttons).
    * 2 = detail view (blocks action buttons).
    * @param {Object} searchProductsHeader - Data table headers
    * @param {Boolean} dialogM.dialog - True opens modal detail.
    */
    async detailsNonCompliantProducts (item,view) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.detailsNonCompliantProducts({
        idHealthAlert:item.idHealthAlert,
      })
      .then((response) => {
        let { data } = response;
        this.$refs.modalMovementDetail.reloadComponent(data);
        this.dialogM.dialog = true;
      })
      .catch ((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
      })
    },

    /**
    * Gets on transit details of selected item. / Obtiene detalles de transito del elemento seleccionado. --
    * Sends data response to component. / Envia datos de respuesta al componente.
    * @method 
    * @param {Object} item - Selected item.
    */
    async onTransitNonCompliantProducts (item) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.onTransitNonCompliantProducts({
        stockId:item.stockId
      })
      .then((response) => {
        let { data } = response;
        this.$refs.modalOnTransit.reloadComponent(data,item.stockId);
      })
      .catch((error) => {
        console.error(error) 
        this.alert("internal");
      })
      .finally(() => {
        this.loading.hide();
      })
    },

    /**
    * Resets variables in use and sets action view. // Reestablece variables en uso y establece la accion de vista.
    * @method 
    * @param {number} action - View.
    * 1 = history view
    * 2 = create movement
    * 3 = edit movement
    */
    changeAction (action) {
      switch (action) {
        case 'listar': 
          this.selected = [];
          this.stepperModel =  1;
          this.ncpModel = {};
          this.ncpModel.productAlertList = [];
          this.showTableResults = false;
          this.selectedProducts = [];
          this.action = 1;  
          this.checkbox = false;
        break;
        case 'crear': 
          this.modelProduct = [];
          this.action = 2;
        break;
      }
    },

    resetSearch() {
      this.showTableResults = false;
    },

    /**
    * Gets all ncp movements (History) . / Obtiene historial de movimientos. --
    * Shows data table. / Muestra la tabla de datos.
    * @method 
    */
    async listAllNonCompliantProducts (pagesAtributes) {
      this.loading = this.$UtilFront.getLoadding();
      await NonCompliantProductRequest.listAllNonCompliantProducts(
        pagesAtributes
      )
      .then((response) => {
        let { data } = response;
        this.$refs.tableNonCompliantProduct.reloadComponent(data,true);
      })
      .catch ((error) => {
        console.log(error);
        this.$refs.alertMessageComponent.reloadComponent("internal",error)
      })
      .finally(() =>{
        this.loading.hide();
      })
    },
  },


  created () { },

  watch: {
    // Routing validation.
    '$route.params.action': {
    handler: function(action) {
      if( action == 'listar' || action == 'crear'){
        this.changeAction(action);
      }
      else {
        this.$router.push('/dashboard')
      }
    },
    deep: true,
    immediate: true
  },
    "$i18n.locale": function () {
      this.historyHeaders = NON_COMPLIANT_PRODUCT();
      this.searchProductsHeader = NON_COMPLIANT_PRODUCT_SEARCH();
    },
    //Changes headers on search products table
    ncpModel:{ handler(n,o){
      if(this.action !=1){
        if(this.ncpModel.motive.value != 1 ){ // Alerta Sanitaria
          this.searchProductsHeader = NON_COMPLIANT_PRODUCT_SEARCH_QUANTITY_TO_BLOCK();
        }else{
          this.searchProductsHeader =  NON_COMPLIANT_PRODUCT_SEARCH();
        }
      }
    },
    deep:true
    },
    //Hide search table if modelProduct list = 0
    modelProduct:{ handler(n,o){
      if ( this.modelProduct.length == 0 ) this.showTableResults = false;
    },
    deep:true
    },
    // selected: {handler (newSelect, oldMotive) {
    //   // If view != history 
    //   // Adds selected products to final model
    //   // Enables button to step 2
    //   console.log(newSelect);
    //    if(this.action != 1 && newSelect.length != 0){
    //      let merged = newSelect.reduce(function(prev, next) {
    //        return prev.concat(next);
    //      });
    //      this.ncpModel.productAlertList = merged;
    //    }
      
    //          console.log(this.ncpModel.productAlertList);

    // },
    //     deep:true
    // }
  },
     /**
    * @description Componentes utilizados en la vista principal de Producto No Conforme
    * @prop {Components} ModalMovementDetailComponent {@link component:ModalMovementDetailComponent} - Muestra los detalles generales de un movimiento.
    * @prop {Components} ModalOnTransitComponent {@link component:ModalOnTransitComponent} - Muestra los detalles de envios en transito de un producto.
    * @prop {Components} NonCompliantProductTableComponent {@link component:NonCompliantProductTableComponent} - Muestra el historial de registros de movimientos.
    * @prop {Components} NonCompliantProductAddProductFormComponent {@link component:NonCompliantProductAddProductFormComponent} - Permite agregar productos a una lista de busqueda para la creacion de un movimiento.
    * @prop {Components} NonCompliantProductSearchTableComponent {@link component:NonCompliantProductSearchTableComponent} - Muestra el resultado de los productos buscados.
    * @prop {Components} NonCompliantProductFormComponent {@link component:NonCompliantProductFormComponent} - Permite agregar la informacion complementaria necesaria para la creacion de un movimiento.
    */
    components: {
    ModalMovementDetailComponent,
    ModalOnTransitComponent,
    NonCompliantProductTableComponent,
    NonCompliantProductAddProductFormComponent,
    NonCompliantProductSearchTableComponent,
    NonCompliantProductFormComponent,
    },
};
</script>


<style scoped >
  ::v-deep.v-stepper__header {
    box-shadow: none;
    height: auto !important;
  } 
  ::v-deep.no-transition .v-stepper__content { transition: none; }

  .gey-color{
    background-color:#f1f1f1
  }

  @media only screen and (max-width: 959.98px){
    ::v-deep.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
        display: flex !important;
        padding-left: 1rem !important;
    }
  }
</style>

<style>
  .v-application .mb-12 {
      margin-bottom: 0px!important;
  }
 .swal2-progress-steps .swal2-progress-step {
  background: #4CAF50  !important;
  } 

  .swal2-progress-steps .swal2-progress-step-line {
    background: #4CAF50  !important;
  } 

  .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
      background: #4CAF50!important;
  }

  .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line {
      background: #9e9e9e !important;
  }

  .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step {
      background: #9e9e9e !important;
      color: #fff !important;
  }

  .swal2-loader {
      border-color: #4CAF50 transparent #4CAF50 transparent !important;
  } 

</style>