<template>
  <div class="px-2">
    <v-data-table
      :headers="headers"
      :items="dataList.content"
      fixed-header
      height="auto"
      :options.sync="options"
      :server-items-length="dataList.totalElements"
      :page.sync="page"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
      item-key="id"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right">
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchValue"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!--v-if="isDetail" -->
        <v-tooltip v-if="isDetail" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isReturn" :disabled="item.status == 'Devuelto a almacén'" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              :class="{ 'hand' : item.status == 'Creado', 'text-muted' : item.status != 'Creado'  } "
              @click="item.status == 'Creado' ? alertReturnStock(item) : null"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-rotate-ccw"></i>
            </span>
          </template>
          <span>Devolver Productos</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
/**
 * @component NonCompliantProductTableComponent
 * @desc Muestra el historial de registros de movimientos. 
 * @author Yair Alvarez
 */

export default {
  props: {
    /** @vprop {Object} [pagesAtributes]                - Show alert function. / Funcion de mostrar alerta. */
    /** @vprop {Array|Object} [headers = []]            - Headers for the table. / Cabeceras para la tabla. */
    /** @vprop {Boolean} [isDetail = false]             - Detail permission. / Permiso de detalle. */
    /** @vprop {Boolean} [isReturn = false]             - Return products permission / Permiso de devolucion de productos.  */
    /** @vprop {Function} [listAllNonCompliantProducts] - Movements history. / Historial de movimientos. */

    pagesAtributes: Object,
    headers: {
      type:Array
    },
    isDetail:{
      type:Boolean,
      default:false
    },
    isReturn:{
      type:Boolean,
      default:false
    },
    listAllNonCompliantProducts:Function,
  },
  data() {
    return {
    /** * @member {String} search         - Search input. / Input de búsqueda.  */
    /** * @member {Array|Object} dataList - Movement history data. / Datos de historial de movimientos.  */
    /** * @member {Object} options        - Pagination options. / Opciones de paginado. */
      search: "",
      dataList: [],
      options: {},
      page: 1,
    };
  },
  components: {},
  watch: {
    search() {
      if (this.search == "") {
        this.searchValue();
      }
    },
    options: {
      handler () {
      this.pagination();
      },
      deep: true,
    },
  },

  methods: {
    /**
    * Table pagination. / Paginado de la tabla.
    * @method
    */
    async pagination () {
      const { page, itemsPerPage } = this.options;
      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      await this.listAllNonCompliantProducts(this.pagesAtributes);
    },
    /**
    * Search input. / Input de búsqueda.
    * @method
    */
    async searchValue () {
      this.pagesAtributes.search = this.search;
      await this.listAllNonCompliantProducts(this.pagesAtributes)
      this.page = 1;
    },

    /**
    * Calls detail function in main view. / Llama a la función de detalle en la vista principal. --
    * Sends selected movement data. / Envia datos del movimiento seleccionado.
    * @method 
    * @param {Object} item - Selected movement data.
    */
    detail (item) {
      this.$emit("detail", item);
    },

    /**
    * Calls returnStock alert in main view. / Llama la alaerta de resturnStock en la vista principal. --
    * Sends selected movement data. / Envia los datos del movimiento seleccionado.
    * @method 
    * @param {Object} item - Selected movement data.
    */
    alertReturnStock (item) {
      this.$emit("alertReturnStock", item);
    },

    /**
    * Reload component. / Recarga componente.
    * @method 
    * @param {Object} dataList - Movement history data from main view.
    */
    reloadComponent (data) {
      this.dataList = data;
      this.dataList.content.map(element => {
        element.motive == 1 ? element.motive = 'Alerta Sanitaria' : '';
        element.status == 1 ? element.status = 'Creado' : element.status = 'Devuelto a almacén';
      })
    },
  },
};
</script>
