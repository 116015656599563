<template>
  <v-dialog
    v-model="dialogM.dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue-fmedical">
        <v-toolbar-title class="pt-4">
          <p class="text-center text-body-2 text-sm-body-1 text-md-h6">
            <i class=""></i> 
            {{ $t("non_compliant_product.detail") }} de producto no conforme - {{ model.idHealthAlert }}
          </p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialogM.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-md-12 pb-0">
            <div class="col-md-12 pb-0">
              <p class="pt-3">
                <i class="fa fa-info mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>Información del movimiento: </span>
                </strong>
              </p>
            </div>
            <div class="card border-light">
              <div class="card-header card-header-detail">
                <i class="fa fa-male mr-2 text-fmedical-blue" />
                <strong>
                  <span> {{ $t("label.responsible") }}:</span>
                </strong>
                 {{ model.generatedBy }} 
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i class="feather icon-tag mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> ID Movimiento:</span>
                      </strong>
                      {{ model.idHealthAlert }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-file-text mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span>Folio/Referencia:</span>
                      </strong>
                      {{ model.alertKey }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-clipboard  mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Motivo:</span>
                      </strong>
                      {{
                        model.motiveText
                      }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-calendar mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span>Fecha de Emisión:</span>
                      </strong>
                      {{ model.createDateText }}
                    </p>
                  </div>
                   <div class="col-xs-12 col-md-4">
                    <p class="pt-3">
                      <i
                        class="feather icon-paperclip mr-1 text-fmedical-blue"
                      ></i>
                      <strong class="pt-0">
                        <span> Archivo adjunto:</span>
                      </strong>
                      {{ model.path }} 
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">                       
                          <span
                            v-bind="attrs"
                            v-on="on"
                            class="hand text-fmedical-red pl-1"
                            @click="downloadPDF(model.idHealthAlert)">
                           <i class="feather icon-file-text mr-1"></i>
                          </span>
                        </template>
                        <span>Descargar PDF</span>
                      </v-tooltip>
                    </p>
                  </div> 
                   <div class="col-xs-12 col-md-12">
                    <p class="pt-3">
                      <i
                        class="feather icon-message-square mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Observación:</span>
                      </strong>
                      {{ model.comment ? model.comment:  "Sin observaciones"}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pb-10 px-2">
            <NonCompliantProductSearchTableComponent
              ref="productSearchTableNonCompliantProductDetails"
              :headers="detailsHeader"
              :showSelects="showSelects"
              @onTransitNonCompliantProducts="onTransitNonCompliantProducts"
            />
          </div>
        </div>
      </div>
    </v-card>
 
  </v-dialog>
</template>

<script>
/**
 * @component ModalMovementDetailComponent
 * @desc Muestra los detalles generales de un movimiento. 
 * @author Yair Alvarez
 */

import NonCompliantProductSearchTableComponent from "@/components/non-compliant-product/NonCompliantProductSearchTableComponent";

export default {
  props: {
    /** @vprop {Object} [detailsHeader = []]              - Default headers for search products table. / Cabezeras default para la tabla de busqueda de productos. */
    /** @vprop {Object} [dialogM]                         - Modal details. / Modal de detalles. */  
    /** @vprop {Function} [alert]                         - Show alert function. / Funcion mostrar alerta.  */
    /** @vprop {Function} [onTransitNonCompliantProducts] - Gets on transit details. Obtene detalles de en transito. */  
    /** @vprop {Function} [listAllNonCompliantProducts]   - Gets movement history. / Obtiene historial de movimientos. */

    detailsHeader: {
      type: Array,
      default: []
    },
    dialogM: {
      type: Object,
    },
    alert: Function,  
    onTransitNonCompliantProducts: Function,
    listAllNonCompliantProducts: Function,
  },

  data() {
    return {
    /** * @member {Object} model        - General movement info model. / Modelo de la informacion general del movimiento. */
    /** * @member {Boolean} showSelects - Disables select inputs in products search table. / Deshabilita inputs de selects en la tabla de busqueda de productos. */
      model:{},
      showSelects:false,
    };
  },

  /**
  * @description Componentes utilizados en la vista de Detalles.
  * @prop {Components} NonCompliantProductSearchTableComponent {@link component:NonCompliantProductSearchTableComponent} - Componente tabla.
  */
  components: {
    NonCompliantProductSearchTableComponent,
  },
  methods:{
    /**
    * Calls download pdf function. / LLama a la funcion de descargar pdf.
    * @method 
    */
    downloadPDF (item) {
      this.$emit("downloadPDF", item );
    },

    /**
    * Reloads component view. / Recarga la vista del componente. --
    * Sets model values. / Establece los valores del modelo. --
    * Reloads view productSearchTableNonCompliantProductDetails. / Recarga la vista productSearchTableNonCompliantProductDetails.
    * @method
    * @param {Array|Object} model.productAlertList - Product alert list.
    */
    reloadComponent(data,){
      this.model = data;
      setTimeout(() => {
        this.$refs.productSearchTableNonCompliantProductDetails.reloadComponent(this.model.productAlerts,1); // 2 expand collapses
      },1);
    },
  },

};
</script>
