var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList.content,"fixed-header":"","height":"auto","options":_vm.options,"server-items-length":_vm.dataList.totalElements,"page":_vm.page,"footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchValue.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"hand mr-2",on:{"click":function($event){return _vm.detail(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.detail")))])]):_vm._e(),(_vm.isReturn)?_c('v-tooltip',{attrs:{"disabled":item.status == 'Devuelto a almacén',"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:{ 'hand' : item.status == 'Creado', 'text-muted' : item.status != 'Creado'  },on:{"click":function($event){item.status == 'Creado' ? _vm.alertReturnStock(item) : null}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-rotate-ccw"})])]}}],null,true)},[_c('span',[_vm._v("Devolver Productos")])]):_vm._e()]}}],null,true)}),_c('ButtonPrintComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }