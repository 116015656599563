<template>
  <div class="px-2" style="z-index: 0;position: relative;">
   <div 
      v-if="step == 100" 
      class="row pt-2 pb-8"
      style="display: flex;"
    >

      <div class="col-12 pb-0">
        <v-autocomplete
          outlined
          multiple
          clearable
          v-model="activeFilters.productKey"
          :items="filters.productKey"
          :label="$t('label.products')"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0"
              color="secondary"
              small>
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ activeFilters.productKey.length - 1 }})
            </span>
          </template> 
        </v-autocomplete>
      </div>

      <div class="col-md-3 pb-0">
        <v-autocomplete
          v-model="activeFilters.lot"
          :items="filters.lot"
          label="Lotes"
          outlined
          multiple
          clearable
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0"
              color="secondary"
              small>
              <span>{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ activeFilters.lot.length - 1 }})
            </span>
          </template> 
        </v-autocomplete>
      </div>

      <div class="col-md-6 pb-0">
        <v-autocomplete
          v-model="activeFilters.nameSupplier"
          :items="filters.nameSupplier"
          outlined
          clearable
          multiple
          :no-data-text="$t('label.no_results')"
          :label="$t('label.suppliers')"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0"
              color="secondary"
              small>
              <span v-if="item != null">{{ item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
            >
              (+{{ activeFilters.nameSupplier.length - 1 }})
            </span>
          </template> 
        </v-autocomplete>
      </div> 

      <div class="col-md-3 pb-0">
        <validation-provider
          v-slot="{ errors }"
          name="lot"
        >
          <v-select
            v-model="activeFilters.expired"
            :items="caducidad"
            label="Caducidad"
            outlined
            :error-messages="errors"
          >
          </v-select>
        </validation-provider>
      </div>

       <div class="row py-0 pr-4">
        <div class="col pb-0">
          <div class="float-right">
            <button
              class="btn btn-outline-success">
              Aplicar filtro
            </button>
          </div>
        </div>
      </div>    
    </div> 
      
    <v-data-table
      class="pt-0 pb-0"
      v-model="productAlertList[0]"
      :single-select="false"
      :show-select="showSelects"
      :headers="headers"
      :items="dataFiltered"
      :search="search"
      fixed-header
      height="auto"
      :footer-props="{
       'items-per-page-options': [dataList.length],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
      item-key="stockId"
    >

      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 mr-2 text-right">
              <v-text-field
                class="br"
                v-model="search"
                :label="$t('table.search')"
              ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.quantityToBlock`]="props">
        <validation-observer ref="observerNCP" >
          <validation-provider
            v-slot="{ errors }"
            name="quantity"
            :rules="`required|min_value:1|max_value:${( props.item.quantity)}`"
          >
            <v-text-field
              @change="inputText(props.item)"
              :disabled="!toBlock"
              v-model="props.item.quantity_to_block"
              label="Edit"
              single-line
              :error-messages="errors"
              required
              min="0"
            ></v-text-field>
          </validation-provider>
        </validation-observer>
      </template>

      <!-- <template v-slot:[`item.stockStatus`]="{ item }">
        <v-chip v-if="item.stockStatus == 1" color="green" dark class="m-1">
            Activo
        </v-chip>
        <v-chip v-if="item.stockStatus == 3" color="grey" dark class="m-1">
            Producto no conforme
        </v-chip>
        <v-chip v-if="item.stockStatus == 4" color="red" dark class="m-1">
            Cancelado
        </v-chip>
        <v-chip v-if="item.stockStatus == 6" color="red" dark class="m-1">
            Recepcionado
        </v-chip>
      </template> -->

      <template v-slot:[`item.disable`]="{ item }">
        <v-chip v-if="item.disable" color="red" dark class="m-1">
            Inactivo
        </v-chip>
        <v-chip v-else color="green" dark class="m-1">
            Activo
        </v-chip>
      </template>

      <template v-slot:[`item.inTransit`]="{ item }">
        <v-tooltip v-if="item.inTransit" top >
          <template v-slot:activator="{ on, attrs }">
            <!-- <span>{{item.quantityInShipment}} <br></span>  -->
            <span  
              class="hand text-fmedical-blue mr-2"
              @click="onTransit(item)"
              v-bind="attrs"
              v-on="on"
            > 
            <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>{{ $t("table.detail") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/**
 * @component NonCompliantProductSearchTableComponent
 * @desc Muestra el resultado de los productos buscados.
 * @author Yair Alvarez
 */
import moment from "moment";

export default {
  props: {
    /** @vprop {Array|Object} [headers = []]    - Headers for the table. / Cabeceras para la table. */
    /** @vprop {Array} [productAlertList = []]  - Table data. / Datos de la tabla. */
    /** @vprop {Boolean} [showSelects = true]   - Shows selects / . Muestra selects.  */
    /** @vprop {Boolean} [toBlock = true]       - Enables quantity input. / Habilita el input de cantidad. */


    headers:{
      type:Array
    },
    productAlertList:{
      type:Array,
      default(){
        return []
      }
    },
    showSelects:{
      type:Boolean,
      default:true
    },
    toBlock:{
      type:Boolean,
      default:true
    },
  },
  data() {
    return {
    /** * @member {String} today           - Today date. / Fecha actual. */
    /** * @member {Array|Object} caducidad - Expired filters / Filtros caducidad. */
    /** * @member {Object} filters         - Filter inputs / Inputs filtro. */
    /** * @member {Boolean} activeFilters  - Active filters / Filtros activos. */
    /** * @member {Object} dataFiltered    - Filtered data / Datos filtrados. */
    /** * @member {Object} step            - Step validation / Validacion step. */
    /** * @member {Array|Object} dataList  - Table data / Datos de tabla. */
    /** * @member {Object} search          - Search inputs / Inputs busqueda. */

      today: moment().format('YYYY-MM-DD'),
      caducidad:[
        {text:'Todos',value:1},
        {text:'Caducos',value:2},
        {text:'No Caducos',value:3},
      ],
      filters:{
        productKey:[],
        lot:[], 
        nameSupplier:[], 
        expired:{ text:'Todos', value:1 }
      },
      activeFilters:{
        productKey:[],
        lot:[], 
        nameSupplier:[], 
        expired:{ text:'Todos', value:1 }
      },
      dataFiltered:[],
      step:0,
      dataList: [],
      search: '',
    };
  },
  components: { },
  watch:{
    activeFilters:{ handler(n,o){ 
      // if(this.activeFilters[0].productKey.length != this.filters.productKey.length){
      const products = this.dataList.filter((item) => this.activeFilters.productKey.some(value  => value .split(" ")[0] === item.productKey));
      const lots = products.filter((item) => this.activeFilters.lot.some(value  => value  === item.lot));
      const suppliers = lots.filter((item) => this.activeFilters.nameSupplier.some(value => value === item.nameSupplier));
      this.dataFiltered = suppliers;

      if (this.activeFilters.expired == 2) {
        this.dataFiltered = suppliers.filter(item => {
          return moment(this.today).isSameOrAfter(item.expirationDate);
        })
      } 

      if (this.activeFilters.expired == 3) {
        this.dataFiltered = suppliers.filter(item => {
          return moment(this.today).isSameOrBefore(item.expirationDate);
        })    
      } 

      // }
      // else {
      //   const products = this.dataList
      //   const lots = products.filter((item) => this.activeFilters[1].lot.every(value  => value  === item.lot));
      //   const suppliers = lots.filter((item) => this.activeFilters[2].nameSupplier.every(value => value === item.nameSupplier));
      //   this.dataFiltered3 = suppliers;

      //   }
    },
      deep:true
    },
  },
  computed:{
    filteredItems() {
      console.log(this.filter);
      return this.dataList.filter((i) => {
        for (let index = 0; index < this.filter.product.length; index++) {
          return !this.filter.product[index] || (i.productKey === (this.filter.product[index].split(" ")[0])) ;
        }
    
        for (let index = 0; index < this.filter.lot.length; index++) {
          return !this.filter.lot[index] || (i.lot === (this.filter.lot[index])) ;
        //console.log(this.filter.product);
        // this.filter.product.map((product, index) => {
        //      return !product || (i.productKey === (product.split(" ")[0])) ;
        //     })
        }

        for (let index = 0; index < this.filter.nameSupplier.length; index++) {
          return !this.filter.nameSupplier[index] || (i.nameSupplier === (this.filter.nameSupplier[index])) ;
        //console.log(this.filter.product);
        // this.filter.product.map((product, index) => {
        //      return !product || (i.productKey === (product.split(" ")[0])) ;
        //     })
        }
        if (this.filter.expired == 2) {
          return moment(this.today).isSameOrAfter(i.expirationDate);
        } 
        if (this.filter.expired == 3) {
          return moment(this.today).isSameOrBefore(i.expirationDate);
        } 

        return true
      })
    },
  },
  methods: {
    initFilters () {
      for (let col in this.filters) {
        if(col != 'expired'){
          this.filters[col] = this.dataList.map((d) => { return d[col] }).filter(
            (value, index, self) => { 
              return self.indexOf(value) === index }
          )
        }
      }
      // TODO restore previous activeFilters before add/remove item
      this.activeFilters = Object.assign({}, this.filters)
      console.log(this.activeFilters);

    },

    /**
    * Gets on transit item details and opens modal. / Obtiene detalles de transito del elemento seleccionado y abre el modal.
    * @method
    * @param {Object} item - Selected product. 
    */
    onTransit (item) {
      this.$emit("onTransitNonCompliantProducts", item);
    },

    /**
    * Validates input quantity to block. / Valida el input de cantidad a bloquear.
    * @method
    * @param {Object} item - Selected product.  
    */
    inputText (val) {
      console.log(val);
      if(parseInt(val.quantity_to_block) <= 0 || val.quantity_to_block == "" ) val.quantity_to_block = 1;
        else if(parseInt(val.quantity_to_block) > val.quantity ) val.quantity_to_block = val.quantity;
          else if(parseInt(val.quantity_to_block) > 0 && parseInt(val.quantity_to_block) <= val.quantity ) val.quantity_to_block;
    },

    /**
    * Reloads data table. / Recarga los datos de la tabla.
    * @method
    * @param {Object} dataList - Product data. 
    * @param {number} step     - Step number.
    */
    reloadComponent (dataList,step) {
      this.search = ''
      this.dataList = dataList;
      this.productAlertList[0] = this.dataList
      this.step = step;
 
      if (this.step == 1) {
        //this.initFilters();
        this.filters.productKey = [...new Set(this.dataList.map(item => item.productKey + " | " + item.completeDescription))];
        this.filters.lot = [...new Set(this.dataList.map(item => item.lot))];
        this.filters.nameSupplier = [...new Set(this.dataList.map(item => item.nameSupplier))];
        
        this.activeFilters.productKey = this.filters.productKey 
        this.activeFilters.lot = this.filters.lot 
        this.activeFilters.nameSupplier = this.filters.nameSupplier 
      }
      else this.dataFiltered = this.dataList
    },
  },
};
</script>
