var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 pt-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelProduct,"height":"auto","fixed-header":"","footer-props":{
            'items-per-page-options': [15],
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"btn-right",attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"btn btn-outline-success float-right "},'button',attrs,false),on),[_c('i',{staticClass:"ti-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t("global.add_product"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"px-4 pt-5"},[_c('validation-observer',{ref:"observerNCP",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validSearch.apply(null, arguments)}}},[_c('v-toolbar',{staticClass:"header"},[_c('v-toolbar-title',[_c('i',{staticClass:"feather icon-plus mr-4"}),_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.formTitle))])])],1),_c('v-card-text',[_c('div',{staticClass:"row pt-8 pb-0",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"br",attrs:{"outlined":"","error-messages":errors,"items":_vm.productsList,"item-text":"text","item-value":"idProduct","label":_vm.$t('label.product'),"return-object":"","disabled":_vm.editedIndex!=-1},on:{"change":_vm.getLots},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{staticClass:"pt-3 pb-3"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"selection",fn:function(data){return [_c('div',{staticClass:"pt-5"},[_vm._v(" "+_vm._s(data.item.text)+" ")])]}}],null,true),model:{value:(_vm.editedItem.product),callback:function ($$v) {_vm.$set(_vm.editedItem, "product", $$v)},expression:"editedItem.product"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"lot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.lots,"item-text":"lot","item-value":"lot","label":"Lotes*","multiple":"","clearable":"","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary"}},[_c('span',[_vm._v(_vm._s(item.lot))])])]}}],null,true),model:{value:(_vm.editedItem.lot),callback:function ($$v) {_vm.$set(_vm.editedItem, "lot", $$v)},expression:"editedItem.lot"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"supplier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"item-text":"text","item-value":"value","clearable":"","error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.supplier_optional_'),"return-object":""},model:{value:(_vm.editedItem.supplier),callback:function ($$v) {_vm.$set(_vm.editedItem, "supplier", $$v)},expression:"editedItem.supplier"}})]}}],null,true)})],1)])]),_c('v-card-actions',{staticClass:"pb-4 pr-4"},[_c('v-spacer'),_c('button',{staticClass:"btn btn-outline-success mr-2",attrs:{"type":"button","disabled":invalid},on:{"click":_vm.save}},[_c('i',{staticClass:"feather icon-file-plus mr-1"}),_vm._v(" Agregar ")]),_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('i',{staticClass:"feather icon-alert-circle mr-1"}),_vm._v(" Cancelar ")])],1)],1)]}}])})],1)],1)],1)]},proxy:true},{key:"item.lot",fn:function(ref){
          var item = ref.item;
return [(item.lot != null && item.lot.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-chip',{attrs:{"color":"secondary","dark":""}},[_vm._v(" "+_vm._s(item.lot[0])+" ")]),_vm._v(" "+_vm._s(item.lot.length != 1 ? "(+" + (item.lot.length - 1) + ")" :"")+" ")],1)]}}],null,true)},_vm._l((item.lot.slice(1)),function(lot){return _c('span',{key:lot},[_vm._v(" "+_vm._s(lot)+" "),_c('br')])}),0):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" feather icon-trash-2 ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.delete")))])])]}}],null,true)}),(_vm.modelProduct.length != 0)?_c('div',{staticClass:"row pt-5 pb-0 pr-0"},[_c('div',{staticClass:"col pb-0"},[_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"btn btn-outline-success",on:{"click":_vm.validSearch}},[_c('i',{staticClass:"feather icon-search mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.search"))+" ")])])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }